import React, { useState, useEffect } from "react";
import axios from "axios";
import { stateAbbreviations } from "./stateAbbrevations";

const MapboxAutocomplete = ({ onChange, onError, defaultValue }) => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasSelected, setHasSelected] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      setQuery(defaultValue);
      setSuggestions([]);
      setHasSelected(true);
    }
  }, []);

  const token =
    "pk.eyJ1IjoiYW5uYXBlcmlzY29wZSIsImEiOiJjbHo2N3AzZHUwcnpzMmpvaHhyMnlsN2F6In0.1jK1DfyPygrQ95fXiwatyg";

  useEffect(() => {
    if (query.length > 2 && !hasSelected) {
      const fetchSuggestions = async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
              query
            )}.json`,
            {
              params: {
                access_token: token,
                autocomplete: true,
                limit: 5,
                country: "us",
                types: "place,postcode",
              },
            }
          );

          const processedSuggestions = response.data.features.map((feature) => {
            let placeName = feature.place_name;

            for (const [stateName, stateAbbreviation] of Object.entries(
              stateAbbreviations
            )) {
              if (placeName.includes(`, ${stateName}`)) {
                placeName = placeName.replace(
                  `, ${stateName}`,
                  `, ${stateAbbreviation}`
                );
              }
            }

            placeName = placeName.replace(/, United States$/, "");
            placeName = placeName.replace(/\b\d{5}(-\d{4})?\b,? ?/, "");

            return { ...feature, place_name: placeName };
          });

          setSuggestions(processedSuggestions);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };
      fetchSuggestions();
    } else {
      setSuggestions([]);
    }
  }, [query, token, onError, hasSelected]);

  const handleSelect = (place_name) => {
    setQuery(place_name);
    setSuggestions([]);
    setHasSelected(true);
    onChange(place_name);
  };

  return (
    <div className="autocomplete-form-field">
      <input
        style={{ width: "100%" }}
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
          setHasSelected(false); // Reset the flag when user types
        }}
        placeholder="Enter Zip or City"
        className="location-search-input"
      />
      <div className="autocomplete-dropdown-container">
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion, index) => (
          <div
            key={index}
            className="suggestion-item"
            style={{ backgroundColor: "#ffffff", cursor: "pointer" }}
            onClick={() => handleSelect(suggestion.place_name)}
          >
            <span>{suggestion.place_name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MapboxAutocomplete;
